import { GET_ACTIVE_ATTEMPTS } from '../actions/actionTypes';
import { RaceResult } from '../../datatypes/types';

// reducer
const initialState: { activeAttempts: RaceResult[] } = {
  activeAttempts: []
}

function ActiveAttemptsReducer(state = initialState, action:any) {
  switch (action.type) {
    case GET_ACTIVE_ATTEMPTS:
      return action.activeAttempts
    default:
      return state
  }
}

export default ActiveAttemptsReducer