import Network from '../../constants/network';
import { GET_RACE_POINTS } from './actionTypes';
import store from '../store';

export const getRacePoints = (raceId:any, lang:string) => {
   const data = {
      data: {
         type: "race",
         attributes: {
            race_id: raceId
         }
      }
   }
   return async (dispatch: (arg0: { type: string; racePoints: any; }) => void) => {
      try {
         const response = await fetch(Network.backEndApiUrl + 'racepoints?lang=' + (lang),
            {
               method: 'POST',
               headers: {
                  'Content-Type': "application/json"
               },
               body: JSON.stringify(data)
            })
         const resData = await response.json();
         //console.log("checkResp: " + JSON.stringify(resData))
         const racePoints = (resData.data) ? resData.data.map((data:any) => {
            const attributes = data.attributes;
            return {
               coordinates: attributes.coordinates,
               identifier: attributes.identifier,
               image_url: attributes.image_url,
               is_checkin_pont: attributes.is_checkin_pont,
               manual_checkin: attributes.manual_checkin,
               name: attributes.name,
               sequence: attributes.sequence,
               id: data.id,
               point_description: attributes.point_description,
               route_description: attributes.route_description
            }
         }) : []
         dispatch({ type: GET_RACE_POINTS, racePoints: racePoints })
      } catch (err) {
         console.log("DEBUG", `getRacePoints error: ${err}`);
         return;
      }
   }
};