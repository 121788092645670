import React, { Component, useEffect } from 'react'
import './News.scss'
import HeroSection from '../../HeroSection'
import {homeObjOne, homeObjThree } from './Data'
import {useTranslation} from 'react-i18next'

// declare global {
//     interface Window {
//         fbAsyncInit: any
//     }
// }
// window.fbAsyncInit = function() {
//     FB.init({
//         appId:'82195',
//         xfbml: false,
//         version: 'v2.6'
//     });
//     document.dispatchEvent(new Event('fb_init'));
// }
// const useScript = (url:string) => {
//     useEffect(() => {
//         const script = document.createElement('script')
//         script.src = url;
//         script.async = true;
//         document.body.appendChild(script)
//         return () => {
//             document.body.removeChild(script)
//         }
//     }, [url])
// }

function News() {
    const {t, i18n} = useTranslation('common');
    // componentDidMount() {
    //     document.addEventListener('fb_init', e => FB.XFBML.parse());
    // }
    
    return (
        <>
        <HeroSection {...homeObjOne} />
        <div className="mapWrapper">
            <h1 className="newsHeader">{t('nav-news')}</h1>
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOpenTrailSweden%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" 
                title="Open Trail Classic Map"
                width="340"
                height="500"
                scrolling="no" 
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
            </iframe>
        </div>
        </>
    )
        // return (
        //     <>
        //         <HeroSection {...homeObjOne} />
        //         <div className="fb-page" 
        //             data-href="https://www.facebook.com/OpenTrailSweden" 
        //             data-tabs="timeline" 
        //             data-width="" 
        //             data-height="" 
        //             data-small-header="true" 
        //             data-adapt-container-width="true" 
        //             data-hide-cover="false" 
        //             data-show-facepile="true">
        //             <blockquote cite="https://www.facebook.com/OpenTrailSweden" className="fb-xfbml-parse-ignore">
        //                 <a href="https://www.facebook.com/OpenTrailSweden">Open Trail</a>
        //             </blockquote>
        //         </div>
        //     </>
        // )
}

export default News