import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Race, Country } from '../../../datatypes/types'
import { getLocations } from '../../../store/actions/locationActions'
import { RootState } from '../../../store/reducers'
import RaceItem from '../../RaceItem'

function Contries() {

    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();
    const countries = useSelector<RootState, Country[]>(state => state.Locations);
    let countriesToShow;
    if (countries === null) {
        dispatch(getLocations(i18n.language));
    }else {

        //console.log("racesToShow1 " + JSON.stringify(countries, null, 2));
        const newArr:Country[] = [];
        countries.forEach((contry, i, array) => {
            const index = newArr.findIndex((con) => { 
                return con.name === contry.name
            });
            if(index !== -1) {
                const element = newArr.find((con) => {
                    return con.name === contry.name
                })
                newArr[index].races = (element as Country).races.concat(contry.races)
            }else {
                newArr.push(Object.assign({},contry));
            }
        })
        //console.log("racesToShow2 " + JSON.stringify(countries, null, 2));
        countriesToShow = newArr.map((contry, i) =>  {
             return <RaceItem {...{
                key: i.toString(),
                lightBg:true,
                topLine: 'Open Trail',
                headline: contry.name,
                buttonLabel: t("race-more-info"),
                buttonData: contry.races,
                buttonLink: "./races",
                img: contry.image_url
            }} />
        })
    }
    
    return (
        <>
            {countriesToShow}
        </>
    )
    
}

export default Contries
