import React from 'react';
import './Footer.scss';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import { MdFingerprint } from 'react-icons/md';
import {useTranslation} from 'react-i18next'

function Footer() {
    const {t, i18n} = useTranslation('common');
    const year = new Date().getFullYear();
    return (
        <div className='footer-container'>
        <section className='footer-subscription'>
            <p className='footer-subscription-heading'>
            {t('footer-download-app')}
            </p>
            {/* <p className='footer-subscription-text'>
            You can unsubscribe at any time.
            </p> */}
            <div className='app-buttons'>
                <a className="googlePlay" href='https://play.google.com/store/apps/details?id=com.opentrailsweden.opentrail&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank'>
                    <img alt='Ladda ned på Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png'/>
                </a>
                <a className="appStore" href="https://apps.apple.com/se/app/open-trail/id1528835426?mt=8" target='_blank'></a>
            </div>
            {/* <div className='input-areas'>
            <form>
                <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Your Email'
                />
                <Button buttonStyle='btn--outline'>Subscribe</Button>
            </form>
            </div> */}
        </section>
        <div className='footer-links'>
            <div className='footer-link-wrapper'>
            <div className='footer-link-items'>
                <h2>{t('nav-about')}</h2>
                <Link to='/about'>{t('nav-about')}</Link>
            </div>
            <div className='footer-link-items'>
                <h2>{t('nav-contact')}</h2>
                <Link to='/contact'>{t('nav-contact')}</Link>
            </div>
            </div>
            <div className='footer-link-wrapper'>
            <div className='footer-link-items'>
                <h2>{t('Information')}</h2>
                <Link to='/termsAndCondition'>{t('footer-terms-of-purchase')}</Link>
                <Link to='/policy'>{t('footer-privacy-policy')}</Link>
                <Link to='/rules'>{t('footer-rules')}</Link>
            </div>
            <div className='footer-link-items'>
                <h2>Social Media</h2>
                <Link to={{pathname:'https://www.instagram.com/open.trail.sweden/'}} target="blank">Instagram</Link>
                <Link to={{pathname:'https://www.facebook.com/OpenTrailSweden'}} target="blank">Facebook</Link>
            </div>
            </div>
        </div>
        <section className='social-media'>
            <div className='social-media-wrap'>
            {/* <div className='footer-logo'> */}
                <Link to='/' className="footer-logo" >
                     <img src="./images/logo-white.png"/>
                </Link>
            {/* </div> */}
            <small className='website-rights'>{t('footer-copyright')} © {year} Open Trail Sweden</small>
            <div className='social-icons'>
                <Link
                className='social-icon-link'
                to={{pathname:'https://www.facebook.com/OpenTrailSweden'}}
                target='_blank'
                aria-label='Facebook'
                >
                <FaFacebook />
                </Link>
                <Link
                className='social-icon-link'
                to={{pathname:'https://www.instagram.com/open.trail.sweden/'}}
                target='_blank'
                aria-label='Instagram'
                >
                <FaInstagram />
                </Link>
            </div>
            </div>
        </section>
        </div>
    );
}

export default Footer;