import React from 'react'
import {Button} from './Button'
import {Link} from 'react-router-dom'
import './RaceItem.scss';
import {useTranslation} from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/reducers';
import { Race } from '../datatypes/types';
import { getInformation } from "../store/actions/infoActions";
import i18next from 'i18next';

interface IProps {
    lightBg:boolean
    topLine?: string
    lightText?:boolean
    lightTextDesc?:boolean 
    headline?:string
    description?:string
    buttonLabel?:string 
    buttonData?:Race|Race[]
    buttonLink?:string
    searchParams?:string
    img?:string
    alt?:string
    imgStart?:string
}
 
function RaceItem({
    lightBg, topLine, lightText, lightTextDesc, headline, description,
    buttonLabel, buttonData, buttonLink, searchParams, img, alt, imgStart
}:IProps) {
    const {t, i18n} = useTranslation('common');
    return (
        <>
            <div className="backgroundImage">
                <img src={img} alt={alt} className="home__hero-img"/>
                <div className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}>
                    <div className="container">
                        <div className="row home__hero-row" 
                            style={{display:'flex', flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'}}
                        >
                            <div className="col">
                                <div className = "home__hero-text-wrapper">
                                    <div className="top-line">{topLine}</div>
                                    <h1 className={lightText ? 'heading' : 'heading dark'}>{t(headline as string)}</h1>
                                    {/* <p className={lightTextDesc ? 'home__hero-subtitle' : 'home__hero-subtitle dark'}>{information ? information.name :'hehe'}</p> */}
                                    <Link to={{
                                        pathname: buttonLink,
                                        search: searchParams,
                                        state: buttonData
                                    }}>
                                        <Button buttonSize='btn--wide' buttonColor='primary'>
                                            {buttonLabel}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                {/* <div className="home__hero-img-wrapper">
                                    <img src={img} alt={alt} className="home__hero-img"/>
                                </div> */}
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </>
    )
}

export default RaceItem
