import React, { useEffect } from 'react'
import HeroSection from '../../HeroSection'
import MarkdownView from '../../MarkdownView'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Info } from '../../../datatypes/types';
import { getInformation } from "../../../store/actions/infoActions";
import {useTranslation} from 'react-i18next'

import {homeObjOne, homeObjThree, homeObjTwo} from './Data'

function Home() {
    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();
    const information = useSelector<RootState, Info>(state => state.Information);
    useEffect(() => {
        dispatch(getInformation("info/concept", i18n.language));
    }, []);

    return (
        <>
            <HeroSection {...homeObjOne} />
            <MarkdownView {...{markdown: information ? information.text : "text not loaded"}} />
        </>
    )
}

export default Home