import { GET_AMARU } from "../actions/actionTypes";
import { Info } from "../../datatypes/types";

// @ts-ignore: dispatch
function amaruReducer(state: Info = null, action) {
    switch (action.type) {
        case GET_AMARU: {
            return action.amaru;
        }
        default: {
            return state;
        }
    }
};

export default amaruReducer;