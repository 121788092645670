import React, { Component } from 'react'
import ContactForm from '../../ContactForm'
import HeroSection from '../../HeroSection'
import {homeObjOne, homeObjThree } from './Data'

function Contact() {

    return (
        <>
            <HeroSection {...homeObjOne} />
            <ContactForm />
        </>
    )
    
}

export default Contact
