import { GET_RACE_POINTS } from '../actions/actionTypes';
import { RacePoint } from '../../datatypes/types';

// reducer
const initialState: { racePoints: RacePoint[] } = {
  racePoints: []
}

function RacePointsReducer(state = initialState, action:any) {
  switch (action.type) {
    case GET_RACE_POINTS:
      return {
        racePoints: action.racePoints,
      }
    default:
      return state
  }
}

export default RacePointsReducer