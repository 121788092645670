import React from 'react'
import './MarkdownView.scss';
import {useTranslation} from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

interface IProps {
    markdown:string;
}
 
function MarkdownView({markdown}:IProps) {
    return (
        <>
            <div className="markdownWrapper">
                <ReactMarkdown linkTarget="_blank" plugins={[gfm]}>{markdown}</ReactMarkdown>
            </div>
        </>
    )
}

export default MarkdownView
