import React, { ReactNode } from 'react'
import './Button.scss'

const STYLES = ['btn--primary', 'btn--outline']

const SIZES = ['btn--medium', 'btn--large', 'btn--mobile', 'btn--wide']

const COLOR = ['primary', 'blue', 'red', 'green']

interface IProps {
    children:ReactNode;
    type?: "button" | "submit" | "reset" | undefined
    onClick?:any
    buttonStyle?:string 
    buttonSize?:string
    buttonColor?:string
}

export const Button = ({
    children, 
    type, 
    onClick, 
    buttonStyle, 
    buttonSize, 
    buttonColor
}: IProps) => {

    const checkButtonStyle = buttonStyle && STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]
    const checkButtonSize = buttonSize && SIZES.includes(buttonSize) ? buttonSize : SIZES[0]
    const checkButtonColor = buttonColor && COLOR.includes(buttonColor) ? buttonColor : null

    return (
        <button className={`btn ${checkButtonStyle} ${checkButtonSize}
        ${checkButtonColor}`} onClick={onClick} type={type}>{children}</button>
    )
}