import { UserActions } from '../actions/actionTypes';

// reducer
const initialState: { success: any } = {
  success: null
}

function UserActionsReducer(state = initialState, action:any) {
  switch (action.type) {
    case UserActions.SEND_MAIL:
      return initialState
    default:
      return state
  }
}

export default UserActionsReducer