import { combineReducers } from 'redux';
import Information from './InfoReducer';
import AboutUs from './AboutUsReducer';
import Zalan from './ZalanReducer';
import Amaru from './AmaruReducer';
import Pessi from './PessiReducer';
import Locations from './LocationsReducer';
import Races from './RacesReducer';
import RacePoints from './racePoints';
import RaceResults from './raceResults';
import ActiveAttempts from './activeAttempts';
import PlannedStarts from './plannedStarts';
import UserActionsReducer from './userActions';
import { Country, Info, Race, RacePoint, RaceResult } from '../../datatypes/types';

export interface RootState {
    Information: Info,
    AboutUs: Info,
    Zalan: Info,
    Amaru: Info,
    Pessi: Info,
    Locations: Country[],
    Races: Race[],
    RacePoints:RacePoint[],
    RaceResults:RaceResult[],
    ActiveAttempts:RaceResult[],
    PlannedStarts:RaceResult[],
    UserActionsReducer: boolean

}

export default combineReducers({
    Information, AboutUs, Zalan, Amaru, Pessi, Locations, Races, RacePoints, RaceResults, ActiveAttempts, PlannedStarts, UserActionsReducer
});