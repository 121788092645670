import { GET_PESSI } from "../actions/actionTypes";
import { Info } from "../../datatypes/types";

// @ts-ignore: dispatch
function pessiReducer(state: Info = null, action) {
    switch (action.type) {
        case GET_PESSI: {
            return action.pessi;
        }
        default: {
            return state;
        }
    }
};

export default pessiReducer;