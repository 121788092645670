import Network from '../../constants/network';
import { UserActions } from './actionTypes';
import store from '../store';

// @ts-ignore: dispatch
export const sendMail = (name: string, from: string, body:string) => async dispatch => {
   //console.log("enter0 " + name + " " + from + " " + body);
   const requestBody = {
       data: {
           type: "mail",
           attributes: {
               name: name,
               from: from,
               body: body
           }
       }
   }
   try {
      //console.log("enter1.1 " + JSON.stringify(requestBody));
      const response = await fetch(Network.backEndApiUrl + "send_mail", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
            "Pass": "BtB4VyJV0StkeWjgrZQKPChu9pF4FPOl"
         },
         body: JSON.stringify(requestBody)
      })
      //console.log("enter1.2 " + response.status)
      let succ = false
      if (response.status === 200) {
         succ = true
      }
      await dispatch({ type: UserActions.SEND_MAIL, success: succ});

   } catch (err) {
      console.log("error2 " + err)
       return;
   }
};