import { GET_ABOUT_US, GET_INFO, GET_ZALAN } from "../actions/actionTypes";
import { Info } from "../../datatypes/types";

// @ts-ignore: dispatch
function abuoutUsReducer(state: Info = null, action) {
    switch (action.type) {
        case GET_ABOUT_US: {
            return action.aboutUs;
        }
        // case GET_ZALAN: {
        //     return action.zalan;
        // }
        default: {
            return state;
        }
    }
};

export default abuoutUsReducer;