import React, { useEffect, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {useLocation} from "react-router-dom";
import { Race, RacePoint, RaceResult } from '../../../datatypes/types';
import { getRacePoints } from '../../../store/actions/racePoints';
import { RootState } from '../../../store/reducers';
import HeroSection from '../../HeroSection';
import MarkdownView from '../../MarkdownView';
import MaterialTable from "material-table";
import './RaceInfo.scss'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { getRaceResults } from '../../../store/actions/raceResults';
import { getActiveAttempts } from '../../../store/actions/activeAttempts';
import { getPlannedStarts } from '../../../store/actions/plannedStarts';
import { getRaces } from '../../../store/actions/locationActions'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox/>),
    Check: forwardRef((props, ref) => <Check/>),
    Clear: forwardRef((props, ref) => <Clear/>),
    Delete: forwardRef((props, ref) => <DeleteOutline/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight/>),
    Edit: forwardRef((props, ref) => <Edit/>),
    Export: forwardRef((props, ref) => <SaveAlt/>),
    Filter: forwardRef((props, ref) => <FilterList/>),
    FirstPage: forwardRef((props, ref) => <FirstPage/>),
    LastPage: forwardRef((props, ref) => <LastPage/>),
    NextPage: forwardRef((props, ref) => <ChevronRight/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft/>),
    ResetSearch: forwardRef((props, ref) => <Clear/>),
    Search: forwardRef((props, ref) => <Search/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn/>)
  };

function RaceInfo() {
    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();

    const races = useSelector<RootState, Race[]>(state => state.Races);
    useEffect(() => {
        dispatch(getRaces(i18n.language));
    }, []);
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const val = params.get("race_id")

    //let data = useLocation();
    //console.log("checkState" + JSON.stringify(data, null, 2))
    //const race = data?.state as Race
    let race:Race = {
        altitude:"0",
        currency:"",
        description:"",
        distance:"",
        image_url:"",
        lang:"",
        medal_time_m:"",
        medal_time_w:"",
        name:"",
        prepaid:true,
        price:"",
        race_id:"",
        rating:"",
        rating_description:"",
        routes:[{label:"",lang:"",route_id:""}],
        start_point:[]
    };

    //console.log("racesToShow123 " + JSON.stringify(races, null, 2));
    if(races) {
        race = races.find((race) => {
            return race.race_id === val
        }) as Race
    }
    let price = "";
    let medal = "";
    let gpxUrl, mapUrl;
    let homeObjOne = {
        lightBg: true,
        lightText: false,
        lightTextDesc: false,
        topLine: 'Open Trail',
        headline: '',
        description: '',
        buttonLabel: 'Get Started',
        imgStart: '',
        img: race.image_url,
        alt: 'Credit Card'
    };
    if(race) {
        //console.log("race" + JSON.stringify(race, null, 2));
        const routeId = race.routes[0].route_id;
        price = race.price != "0" ? race.price + " " + race.currency : t("race-free")
        medal = race.medal_time_m || race.medal_time_w ? t("yes") : t("no");
        homeObjOne.headline = race.name
        gpxUrl = "https://api.o-trail.com/v0.2/route/gpx?route_id=" + routeId;
        mapUrl = "https://api.o-trail.com/v0.2/route/pdf?route_id=" + routeId;
    }
    const racePoints:any = useSelector<RootState, RacePoint[]>(state => state.RacePoints);
    const raceResults:RaceResult[] = useSelector<RootState, RaceResult[]>(state => state.RaceResults);
    const activeAttempts:RaceResult[] = useSelector<RootState, RaceResult[]>(state => state.ActiveAttempts);
    const plannedStarts:RaceResult[] = useSelector<RootState, RaceResult[]>(state => state.PlannedStarts);
    //console.log("enter " + JSON.stringify(racePoints.racePoints.length))
    //if (racePoints.racePoints.length === 0) {
    useEffect(() => {
        dispatch(getRacePoints(race.race_id, i18n.language));
        dispatch(getRaceResults(race.race_id, i18n.language));
        dispatch(getActiveAttempts(race.race_id, i18n.language));
        dispatch(getPlannedStarts(race.race_id, i18n.language));
    }, [races]);
    //}
    //console.log("raceResults123  " + JSON.stringify(raceResults, null, 2))
    //console.log("racePOints  " + JSON.stringify(racePoints, null, 2))
    //point.coordinates.map((coord)=>{ return coord.toFixed(1)})
    const pointsToShow = racePoints.racePoints.map((point:RacePoint, i:number) =>  {
        const index = (i > 0 && i !== racePoints.racePoints.length - 1) ? i.toString() + ". " : ""
        return <div className="point">
                <h3>{index + point.name}</h3>
                <h3>{"(" + point.coordinates[0] + ", " + point.coordinates[1] + ")"}</h3>
                <img src={point.image_url} alt={point.name} className="raceImage"/>
            </div>
    })

    return (
        <>
            <HeroSection {...homeObjOne} />
            <div className="toprow">
                <h2>{race ? t("race-distance") + ": " + race.distance + " km" : ""}</h2>
                <h2>{race ? t("race-price") + ": " + price  : ""}</h2>
                <h2>{race ? t("race-altitude") + ": " + race.altitude + " m" : ""}</h2>
                <h2>{race ? t("race-difficulty") + ": " + race.rating : ""}</h2>
                <h2>{race ? t("race-medal") + ": " + medal : ""}</h2>
                <h2>{race ? t("race-gpx") + ": " : ""}
                    <a href={gpxUrl}>{t("download")}</a>
                </h2>
                <h2>{race ? t("race-map") + ": " : ""}
                    <a href={mapUrl}>{t("download")}</a>
                </h2>
            </div>
            {/* <div className="mapWrapper">
                <iframe src="https://api.mapbox.com/styles/v1/opentrailsweden/cka8ma3lg2m6f1in5hwsobvbf.html?fresh=true&title=false&access_token=pk.eyJ1Ijoib3BlbnRyYWlsc3dlZGVuIiwiYSI6ImNrOHl0eG00NjA1NDAzbnByOGZ4MjN4cHQifQ.i5PxD6m6hbVwXmOtE0kA6w" 
				    title="Open Trail Classic Map" className="map">
			    </iframe>
            </div> */}
            <h2 className="header">{t("race-points")}</h2>
            <div className="points">
                {pointsToShow}
            </div>
            <MarkdownView {...{markdown: race ? race.description : "text not loaded"}} />
            <div className= "resultTable" style={{ maxWidth: "100%" }}>
                <MaterialTable
                    columns={[
                    { title: t("race-started"), field: "start_datetime" },
                    { title: t("race-recentcheckin"), field: "latest_checkin", type: "numeric" },
                    { title: t("race-lastname"), field: "lastname" },
                    { title: t("race-firstname"), field: "firstname" },
                    { title: t("race-birthyear"), field: "birth_year", type: "numeric" },
                    { title: t("race-club"), field: "club" },
                    { title: t("race-city"), field: "city" },
                    { title: t("race-sex"), field: "gender" },
                    ]}
                    data={activeAttempts.length > 0 ? activeAttempts : [] }
                    title={t("race-live")}
                    icons={tableIcons as any}
                    options = {{
                        emptyRowsWhenPaging:false,
                        pageSize:10,
                        rowStyle: (_data:any, index:number, _level:number) => {
                            return index % 2
                            ? {backgroundColor: "#f2f2f2"}
                            : {};
                        }
                    }}
                    detailPanel={rowData => {
                        //console.log("rowData " + JSON.stringify(rowData))
                        if (!rowData.active_attempt){
                            return <></>
                        }
                        const attemptPoints = rowData.active_attempt.result_points;
                        const points = attemptPoints.map((point) => {
                            const seq = point.sequence ? point.sequence : ""
                            return <tr>
                                <th>{seq}</th>
                                <th>{point.name}</th>
                                <th>{point.total_time}</th>
                                <th>{point.split_time}</th>
                            </tr>
                        })
                        return <>
                            <table className="detailsTable">
                                <tr>
                                    <th>nr</th>
                                    <th>point name</th>
                                    <th>time</th>
                                    <th>split</th>
                                </tr>
                                {points}
                            </table>
                        </>
                      }}
                      onRowClick={(event, rowData, togglePanel:any) => togglePanel()}
                />
            </div>
            <div className= "resultTable" style={{ maxWidth: "100%" }}>
                <MaterialTable
                    columns={[
                    { title: "#", field: "position", type: "numeric" },
                    { title: t("race-lastname"), field: "lastname" },
                    { title: t("race-firstname"), field: "firstname" },
                    { title: t("race-birthyear"), field: "birth_year", type: "numeric" },
                    { title: t("race-club"), field: "club" },
                    { title: t("race-city"), field: "city" },
                    { title: t("race-sex"), field: "gender" },
                    { title: t("race-nationality"), field: "nationality" },
                    { title: t("race-time"), field: "time" },
                    { title: t("race-started"), field: "start_datetime" },
                    ]}
                    data={raceResults.length > 0 ? raceResults : [] }
                    title={t("race-result")}
                    icons={tableIcons as any}
                    options = {{
                        emptyRowsWhenPaging:false,
                        pageSize:10,
                        rowStyle: (_data:any, index:number, _level:number) => {
                            return index % 2
                            ? {backgroundColor: "#f2f2f2"}
                            : {};
                        }
                    }}
                    detailPanel={rowData => {
                        //console.log("rowData " + JSON.stringify(rowData))
                        const resultPoints = rowData.finished_attempts.result_points;
                        const startTimes = rowData.finished_attempts.start_times;
                        //console.log("startTimes " + JSON.stringify(startTimes));
                        const data = startTimes.map((time, i) => {
                            const points = resultPoints.map((point) => {
                                const seq = point.sequence ? point.sequence : ""
                                return <tr>
                                    <th>{seq}</th>
                                    <th>{point.name}</th>
                                    <th>{point.completions[i].total_time}</th>
                                    <th>{point.completions[i].split_time}</th>
                                </tr>
                            })
                            return <>
                                <h2>{new Date(time).toLocaleDateString('en-CA')}</h2>
                                <table className="detailsTable">
                                    <tr>
                                        <th>nr</th>
                                        <th>point name</th>
                                        <th>time</th>
                                        <th>split</th>
                                    </tr>
                                    {points}
                                </table>
                            </>
                        })
                        return (
                        <>
                        {data}
                        </>
                        )
                      }}
                      onRowClick={(event, rowData, togglePanel:any) => togglePanel()}
                />
            </div>
            <div className= "resultTable" style={{ maxWidth: "100%" }}>
                <MaterialTable
                    columns={[
                    { title: t("race-planned-start"), field: "planned_start_datetime" },
                    { title: t("race-lastname"), field: "lastname" },
                    { title: t("race-firstname"), field: "firstname" },
                    { title: t("race-birthyear"), field: "birth_year", type: "numeric" },
                    { title: t("race-club"), field: "club" },
                    { title: t("race-city"), field: "city" },
                    { title: t("race-sex"), field: "gender" },
                    { title: t("race-nationality"), field: "nationality" },
                    ]}
                    data={plannedStarts.length > 0 ? plannedStarts : [] }
                    title={t("race-planned-starters")}
                    icons={tableIcons as any}
                    options = {{
                        emptyRowsWhenPaging:false,
                        pageSize:10,
                        rowStyle: (_data:any, index:number, _level:number) => {
                            return index % 2
                            ? {backgroundColor: "#f2f2f2"}
                            : {};
                        }
                    }}
                />
            </div>
        </>
    )
    
}

export default RaceInfo
