import React from 'react'
import './PersonalPresentation.scss';
import {useTranslation} from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

interface IProps {
    markdown:string;
    imgSrc:string;
}
 
function PersonalPresentation({markdown, imgSrc}:IProps) {
    return (
        <>
            <div className="personal">
                <img src={imgSrc} className="home__hero-img"/>
                <div className="markdownWrapper">
                    <ReactMarkdown plugins={[gfm]}>{markdown}</ReactMarkdown>
                </div>
            </div>
        </>
    )
}

export default PersonalPresentation
