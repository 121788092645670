import { GET_ABOUT_US, GET_INFO } from "../actions/actionTypes";
import { Info } from "../../datatypes/types";

// @ts-ignore: dispatch
function infoReducer(state: Info = null, action) {
    switch (action.type) {
        case GET_INFO: {
            //console.log("checkIT: " + JSON.stringify(action));
            return action.information;
        }
        default: {
            return state;
        }
    }
};

export default infoReducer;