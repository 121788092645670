import { GET_PLANNED_STARTS } from '../actions/actionTypes';
import { RaceResult } from '../../datatypes/types';

// reducer
const initialState: { plannedStarts: RaceResult[] } = {
  plannedStarts: []
}

function PlannedStartsReducer(state = initialState, action:any) {
  switch (action.type) {
    case GET_PLANNED_STARTS:
      return action.plannedStarts
    default:
      return state
  }
}

export default PlannedStartsReducer