import React, { Component, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { Info } from '../../../datatypes/types';
import { getInformation } from '../../../store/actions/infoActions';
import { RootState } from '../../../store/reducers';
import MarkdownView from '../../MarkdownView';

function Policy() {
    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();
    const information = useSelector<RootState, Info>(state => state.Information);
    useEffect(() => {
        dispatch(getInformation("info/privacypolicy", i18n.language));
    }, []);

    return (
        <>
            <MarkdownView {...{markdown:information ? information.text : "text not loaded"}} />
        </>
    )
    
}

export default Policy
