import React, { useEffect, useState } from 'react'
import {useTranslation} from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sendMail } from '../store/actions/userActions';
//import { Axios, db } from '../firebase/firebaseConfig'
import './ContactForm.scss'
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

interface IForm {
    name:string;
    email?: string
    message?:string
}
let form:IForm
const ContactForm = () => {
  const [formData, setFormData] = useState(form)
  const {t, i18n} = useTranslation('common');
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  
  const handleClickToOpen = () => {
    setOpen(true);
  };
  
  const handleToClose = () => {
    setOpen(false);
  };

  const updateInput = (e: { target: { name: any; value: any } }) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    sendEmail()
  }
  const sendEmail = () => {
    //console.log("sendEmail: " + formData.name + " " + formData.email + " " + formData.message)
    if(formData.name && formData.email && formData.message) {
      dispatch(sendMail(formData.name, formData.email as string, formData.message as string))
      handleClickToOpen();
      setFormData({
        name: '',
        email: '',
        message: '',
      })
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleToClose}>
        <DialogTitle>{t('message-sent')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('message-text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToClose} 
                  color="primary" autoFocus>
            {t('dialog-close')}
          </Button>
        </DialogActions>
      </Dialog>

      <div className="email">
          <h1 className = "mailHeader">{t('contact-email')}:</h1>
          <a className="alignBaseLine" href="mailto:info@opentrailsweden.se">info@opentrailsweden.se</a>
      </div>
      <div className="form">
        <div className="formWrapper">
            <form onSubmit={handleSubmit}>
                <input
                type="text"
                name="name"
                placeholder={t('form-name')}
                onChange={updateInput}
                value={formData && formData.name || ''}
                />
                <input
                type="email"
                name="email"
                placeholder={t('form-email')}
                onChange={updateInput}
                value={formData && formData.email || ''}
                />
                <textarea
                //   type="text"
                name="message"
                placeholder={t('form-message')}
                onChange={updateInput}
                value={formData && formData.message || ''}
                ></textarea>
                <button type="submit">{t('form-submit')}</button>
            </form>
        </div>
      </div>
    </>
  )
}

export default ContactForm
