import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Race, Country } from '../../../datatypes/types'
import { getLocations, getRaces } from '../../../store/actions/locationActions'
import { RootState } from '../../../store/reducers'
import RaceItem from '../../RaceItem'
import {useLocation} from "react-router-dom";

function Races() {

    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();
    //const races = useSelector<RootState, Country[]>(state => state.Locations);
    const races = useLocation().state as Race[];
    //console.log("test " + JSON.stringify(races))
    let racesToShow;
    if (races === null) {
        dispatch(getLocations(i18n.language));
    }else {
        //console.log("racesToShow " + JSON.stringify(races, null, 2));
        racesToShow = races.map((race, i) =>  {
            return <RaceItem {...{
               key: race.race_id,
               lightBg: true,
               topLine: 'Open Trail',
               headline: race.name + " " + race.distance + "km",
               buttonLabel: t("race-more-info"),
               buttonData: race,
               buttonLink: "./raceInfo",
               searchParams: "?race_id=" + race.race_id,
               img: race.image_url,
           }} />
       })
    }
    
    return (
        <>
            {racesToShow}
        </>
    )
    
}

export default Races
