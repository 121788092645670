import { GET_RACES } from "../actions/actionTypes";
import { Race } from "../../datatypes/types";

// @ts-ignore: dispatch
function RacesReducer(state: Race[] = null, action) {
    switch (action.type) {
        case GET_RACES: {
            return action.races;
        }
        default: {
            return state;
        }
    }
};

export default RacesReducer;