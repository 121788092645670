import React, { Component, useEffect } from 'react'
import ContactForm from '../../ContactForm'
import HeroSection from '../../HeroSection'
import MarkdownView from '../../MarkdownView'
import {homeObjOne, homeObjThree } from './Data'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Info } from '../../../datatypes/types';
import { getAboutUs, getInformation, getZalan, getAmaru, getPessi } from "../../../store/actions/infoActions";
import {useTranslation} from 'react-i18next'
import PersonalPresentation from '../../PersonalPresentation'
import './About.scss'

function About() {

    const {t, i18n} = useTranslation('common');
    const dispatch = useDispatch();
    const aboutUs = useSelector<RootState, Info>(state => state.AboutUs);
    const keywords = useSelector<RootState, Info>(state => state.Information);
    const zalan = useSelector<RootState, Info>(state => state.Zalan);
    const amaru = useSelector<RootState, Info>(state => state.Amaru);
    const pessi = useSelector<RootState, Info>(state => state.Pessi);
    
    //console.log("enter0" + zalan)
    // if (aboutUs === null) {
    //     console.log("enter1")
    //     dispatch(getAboutUs("info/botond", i18n.language));
    // }
    // if (zalan === null) {
    //     console.log("enter1")
    //     dispatch(getZalan("info/zalan", i18n.language));
    // }
    useEffect(() => {
        dispatch(getInformation("info/keyword", i18n.language));
        dispatch(getAboutUs("info/botond", i18n.language));
        dispatch(getZalan("info/zalan", i18n.language));
        dispatch(getAmaru("info/amaru", i18n.language));
        dispatch(getPessi("info/pessi", i18n.language));
    }, []);

    return (
        <>
            <HeroSection {...homeObjOne} />
            <h1 className="AboutUsHeader">{t('about-who-are-we')}</h1>
            <PersonalPresentation {...{markdown:aboutUs ? aboutUs.text : "text not loaded"}} imgSrc='images/boti.jpg' />
            <PersonalPresentation {...{markdown:zalan ? zalan.text : "text not loaded"}} imgSrc='images/zalan.jpg' />
            <PersonalPresentation {...{markdown:amaru ? amaru.text : "text not loaded"}} imgSrc='images/amaru.jpg' />
            <PersonalPresentation {...{markdown:pessi ? pessi.text : "text not loaded"}} imgSrc='images/pessi.jpg' />
            <MarkdownView {...{markdown: keywords ? keywords.text : "text not loaded"}} />
        </>
    )
}

export default About
