import { GET_RACE_RESULTS } from '../actions/actionTypes';
import { RaceResult } from '../../datatypes/types';

// reducer
const initialState: { raceResults: RaceResult[] } = {
  raceResults: []
}

function RaceResultsReducer(state = initialState, action:any) {
  switch (action.type) {
    case GET_RACE_RESULTS:
      return action.raceResults
    default:
      return state
  }
}

export default RaceResultsReducer